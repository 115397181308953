import { useAppContext } from '@/core/App/AppContext'
import Link from '@/core/Link'
import { useInView } from 'react-intersection-observer'
// import MainSlider, { SwiperSlide } from '../MainSlider/index'
import './ShopbySlider.scss'
import { useEffect, useState } from 'react'
import { Image } from '@/theme/components/Image'
import { Swiper, SwiperSlide } from 'swiper/react'
// import 'swiper/less/navigation';

function visibleOnly(items) {
  return items.filter((item) => item.include_in_menu == 1)
}
// tags
const ShopbyCategories = ({}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [{ isMobile, isTablet, categoryList }] = useAppContext()
  const additionalUrl = '/shop/category/'

  if (!categoryList?.length) return null

  window.addEventListener('resize', () => {
    setWindowWidth(window.innerWidth)
  })

  useEffect(() => {
    const shopby = document.querySelectorAll<HTMLElement>('.shopby')
    shopby.forEach((item) => {
      item.style.height = window.getComputedStyle(item).width
    })
  }, [windowWidth])

  return (
    <>
      <div className="shop-by-category-section">
        {/* {JSON.stringify(categoryList)} */}
        <div className="carousel-section mobile-full-carousel">
          <Swiper
            autoplay={true}
            delay={4500}
            spaceBetween={16}
            centeredSlides={true}
            slidesPerView={isMobile || isTablet ? 2 : 5}
            // pagination={{
            //   clickable: true,
            // }}
            freeMode
            navigation
            loop={true}
          >
            {categoryList &&
              visibleOnly(categoryList).map((item, key) => (
                <SwiperSlide key={key}>
                  <Link
                    href={
                      process.env.APP_MAGENTO_ADMIN_URL +
                      `${additionalUrl}` +
                      item.url_key
                    }
                    title=""
                    alt=""
                    className="CategoryCard"
                  >
                    <div className="image-title-grid">
                      <Image
                        aspectRatio={'48/37'}
                        url={item.homepage_image || ''}
                        alt={item.name}
                        className="img-fluid"
                      />
                    </div>
                    <h2 className="overlays-text">{item.name}</h2>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default function ShopbyCategory(props) {
  return (
    <div className="shop-by-category-container">
      <ShopbyCategories />
    </div>
  )
}
