import { PureComponent } from 'react';

import Link from '@/core/Link';
import CmsPage from '@/theme/route/CmsPage';

export class MenuPage extends PureComponent {
  heading() {
    return null;
  }
  content() {
    return null;
  }
  render() {
    return (
      <div>
        <Link href='cms'>To CMS Page</Link>
      </div>
    );
  }
  
}

export default MenuPage;
