import React from 'react'
import { Image } from '../Image'
import { mediaUrl } from 'src/server/utils'
import Link from '@/core/Link'
import './Consult.scss'
import { useAppContext } from '@/core/App/AppContext'

export default function Consult(props) {
  const [{ isMobile }] = useAppContext()
  return (
    <div className="consult">
      <div className="carousel-section">
        <Image
          url={mediaUrl('codilar/tmp/banner/images/expert-banner-1_1.webp')}
          alt="Consult"
          className="img-fluid w-100"
        />
        <div className="ayurveda-cnt-sec">
          {!isMobile && (
            <div className="heading-view-all-section">
              <h2>Speak to our Ayurveda Experts</h2>
            </div>
          )}
          <p>
            Have our expert Ayurveda medical team attend to you personally and
            answer all your health, diet, and lifestyle concerns!
          </p>
          <p>
            With a combined experience of over 200 Years in the field of
            Ayurvedic Medicine, the medical team at Vedistry is an expert panel
            assigned to Premium Members for consultations throughout their
            association with us.
          </p>
        </div>
      </div>
    </div>
  )
}
