import React from 'react'
import MainSlider, { SwiperSlide } from '../MainSlider'
import Link from '@/core/Link'
import { useAppContext } from '@/core/App/AppContext'
import './AyurvedicScience.scss'
import { assetsUrl } from 'src/server/utils'

const AyurvedicScience = ({
  title,
  slidesPerView = 3,
  posts = [],
  renderItem = null,
  autoHeight = false,
  titleStyle = {},
  articleAfterHeading = '',
}) => {
  const [{ isMobile }] = useAppContext()
  if (!posts?.length) {
    return null
  }
  return (
    <div className="ayurvedic-section">
      {articleAfterHeading && (
        <article className="content">{articleAfterHeading}</article>
      )}

      {title && (
        <div className="heading-view-all-section undefined">
          <h2 className="pb-2">{title}</h2>
        </div>
      )}
      <MainSlider
        slidesPerView={isMobile ? 1 : slidesPerView}
        spaceBetween={30}
        autoHeight={autoHeight}
      >
        {posts?.map((post) => (
          <SwiperSlide key={post.url_key}>
            {renderItem ? (
              renderItem({ item: post, key: post.url_key })
            ) : (
              <Link className="blog-card" href={'/blog/post/' + post.url_key}>
                <div className="ayurvedic-container">
                  <picture className="picture--post-thumbnail">
                    <img
                      src={
                        post.imageUrl
                          ? post.imageUrl
                          : assetsUrl(
                              `/media/mageplaza/blog/post/${post.image}`,
                              {
                                width: 400,
                              }
                            )
                      }
                      alt={post.name}
                      loading="lazy"
                    />
                  </picture>
                </div>
                <div className="ayurvedic-container-info">
                  <h2>{post.name}</h2>
                  <p className="post-item__excerpt">{post.meta_description}</p>
                </div>
                <Link href={'/blog/post/' + post.url_key}>Read More</Link>
              </Link>
            )}
          </SwiperSlide>
        ))}
      </MainSlider>
    </div>
  )
}

AyurvedicScience.defaultProps = {
  title: 'Blogs',
}

export default AyurvedicScience
