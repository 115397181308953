import React from 'react'
import Link from '@/core/Link'
import './NewsCart.scss'

export default function NewsCard(props: any) {
  return (
    <Link href={props.article || ''}>
      <div className="d-flex align-content-between flex-wrap news-card border border-dark mb-5 p-2 text-center">
        <div style={{ width: '100%' }}>
          <img
            src={props.logo}
            alt={'article'}
            loading="lazy"
            className="image-center"
            style={{ width: 150, height: 71 }}
          />
        </div>
        <p className="pt-1 news-para">{props.heading}</p>
      </div>
    </Link>
  )
}
