import { FC, useEffect, useRef } from "react";
import style from './LocalVideoEmbed.module.css'
import { useInView } from "react-intersection-observer";

type Props = {
   src: string
   title: string
   thumbnail: string
   aspectRatio?: string
   aspectRatioTablet?: string
   aspectRatioMobile?: string
   loading?: string
   autoPlay?: boolean
   onEnd?: () => void
}

const LocalVideoEmbed: FC<Props> = props => {
   const { ref, inView, entry } = useInView({
      /* Optional options */
      threshold: 0,
   });
   let parsedSrc = props.src
   parsedSrc = parsedSrc?.replace('https://sm.vedistry.com/media/', '')
   parsedSrc = parsedSrc?.replace('https://vedistry-media.b-cdn.net/media/', '')
   let videoEl = useRef<HTMLVideoElement>(null)
   useEffect(() => {
      if (props.autoPlay) {
         // videoEl.current?.play()
      } else {
         videoEl.current?.pause()
      }
      function triggerNextSlideEvent() {
         setTimeout(() => {
            // alert('Ended')
            props.onEnd && props.onEnd()
         }, 1000);
      }
      videoEl.current?.addEventListener('ended', triggerNextSlideEvent)
      return () => {
         videoEl.current?.removeEventListener('ended', triggerNextSlideEvent)
      }
   }, [props.autoPlay, videoEl])

   useEffect(() => {
      if (!inView) {
         videoEl.current?.pause()
      }
   }, [inView])
   return (
      <div ref={ref} className={style.LocalVideoEmbed}>
         <video controls playsInline poster={props.thumbnail} ref={videoEl}>
            <source src={parsedSrc} type="video/mp4" />
         </video>
      </div>
   )
}

export default LocalVideoEmbed
