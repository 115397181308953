import useGraphQLQuery, {
  useGetGraphQLQuery,
} from '@/theme/lib/useGraphQLQuery'
import { gql } from 'graphql-request'
import product_items from 'src/graphql/queries/product/product_items'
import ProductSlider from '../ProductSlider'
import { useEffect, useState } from 'react'

export default (props) => {
  const [{ data, loading }, request, { reset }] = useGetGraphQLQuery(
    gql`query getProducts($filter: ProductAttributeFilterInput
        $pageSize: Int
        $currentPage: Int
        ) {
          products(filter: $filter, pageSize: $pageSize, currentPage: $currentPage) {
              items {
                  ${product_items({})}
              }
              total_count
          }
      }`,
    {
      filter: props.filter,
      currentPage: props.currentPage,
      pageSize: props.pageSize,
    },
    { immediate: true, authorized: false }
  )
  useEffect(() => {
    request({
      filter: props.filter,
      currentPage: props.currentPage,
      pageSize: props.pageSize,
    })
  }, [props.filter, request])

  const products = data?.products.items || []
  if (products.length == 0 && !loading) return null

  return (
    <ProductSlider
      stock={props.stock}
      filter={props.filter}
      link={props.link}
      categorySlider={props.categorySlider}
      onCategoryChange={props.onCategoryChange}
      title={props.title}
      subTitle={props.subTitle}
      categories={props.categories}
      enableProductsSlider={props.enableProductsSlider}
      productCardVariant={props.productCardVariant}
      centerMode={props.centerMode}
      items={products}
      loading={loading}
    />
  )
}
