import { useInView } from 'react-intersection-observer'
import ProductListBase from './ProductListBase'

export default function ProductListAsync({
  filter,
  title,
  subTitle,
  currentPage = 1,
  pageSize = 10,
  categorySlider,
  categories,
  onCategoryChange,
  stock,
  link,
  centerMode = false,
  enableProductsSlider = true,
}) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })
  return (
    <div ref={ref} className="product-list">
      {inView ? (
        <ProductListBase
          stock={stock}
          title={title}
          subTitle={subTitle}
          filter={filter}
          currentPage={currentPage}
          pageSize={pageSize}
          onCategoryChange={onCategoryChange}
          categorySlider={categorySlider}
          categories={categories}
          enableProductsSlider={enableProductsSlider}
          centerMode={centerMode}
          link={link}
        />
      ) : null}
    </div>
  )
}
