import { FC } from 'react'
import style from './section.css'
import HeadingViewAll from '../HeadingViewAll'

type ISection = {
  title?: string
  subtitle?: string
  link?: string
  description?: string
  class?: string
  children: JSX.Element
}
const Section: FC<ISection> = (props) => {
  return (
    <section className={`${style.section} ${props.class} mt-4`}>
      {props.title && (
        <div className="container">
          <HeadingViewAll
            className={props.titleClass}
            title={props.title}
            link={props.link}
          />
        </div>
      )}
      <div className="container">{props.children}</div>
    </section>
  )
}

export default Section
