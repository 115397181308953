import { useEffect, useState } from "react"
import useLocalStorage from "./useLocalStorage"

function isSpinwheelUsed() {
   if (!localStorage?.getItem('spinwheel_status')) {
      return false
   }
   let val = localStorage?.getItem('spinwheel_status')
   if (val == '1') {
      return true
   }
   return false
}

export default function useSpinwheelSession() {
   const [ status, setStatus ] = useState("idle")
   const [ isChecked, setIsChecked ] = useState(false)
   useEffect(() => {
      if (isSpinwheelUsed()) {
         setStatus('used')
      } else {
         setStatus('unused')
      }
      setIsChecked(true)
   }, [])
   useEffect(() => {
      // localStorage?.setItem('spinwheel_status', status)
   }, [ status ])
   return {
      status,
      isChecked
   }
}
