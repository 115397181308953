import Link from '@/core/Link'
import { useInView } from 'react-intersection-observer'
import Button from '../Button'
import { TvComponent } from '../TvComponent'
import './HomePageTvComponentAsync.scss'
import MainSlider, { SwiperSlide } from '../MainSlider'
import { useAppContext } from '@/core/App/AppContext'
import LoadMore from 'src/framework/components/Load More/LoadMore'
import { assetsUrl, mediaUrl } from 'src/server/utils'
import RenderOnView from '../RenderOnView'
import { Image } from '@/theme/components/Image'
import VideoEmbed from 'src/framework/components/VideoEmbed'
import Section from '../Section/index'

const ButtonLink = (props) => <Button as="a" {...props} />

export const playTvVideo = (e) => {
  const vedaContainerRef = e.target.parentElement
  const playButtonRef = vedaContainerRef.querySelector('img.veda-play-button')
  const thumbnailRef = vedaContainerRef.querySelector('img.video--thumbnail')
  const iframeRef = vedaContainerRef.getElementsByTagName('iframe')[0]
  thumbnailRef.style.display = 'none'
  playButtonRef.style.display = 'none'
  iframeRef.src = iframeRef.src.replace('autoplay=0', 'autoplay=1')
}

export default function HomePageTvComponentAsync(props) {
  const [{ isMobile, isTablet }] = useAppContext()
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  })

  return (
    // <div className="moha-tv-container">
    <div className="charak-vedas-section">
      <MainSlider
        delay={4500}
        spaceBetween={20}
        // slidesPerView={5}
        slidesPerView={isMobile ? 1.2 : isTablet ? 2 : 3}
        autoplay={false}
      >
        {props.videos?.map((item, itemIndex) => {
          return (
            <SwiperSlide key={itemIndex}>
              <VideoEmbed
                thumbnailSrc={mediaUrl(item.thumbnail)}
                src={item.link}
                showPlayIcon={true}
                aspectRatio={'16/9'}
                // rounded={true}
              />
            </SwiperSlide>
          )
        })}
      </MainSlider>
    </div>
    // </div>
  )
}
